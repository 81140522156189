var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      staticClass: "giphy-mission",
      attrs: { column: "", "justify-center": "", "fill-height": "" }
    },
    [
      _c("div", { staticClass: "powered-by" }, [
        _c("a", { attrs: { target: "_blank", href: "https://giphy.com/" } }, [
          _c("img", {
            staticStyle: { width: "110px" },
            attrs: { src: require("../../../../assets/giphy.png") }
          })
        ])
      ]),
      !_vm.gifs.length || _vm.mode === "explain"
        ? _c(
            "v-flex",
            { staticClass: "mission-instructions", attrs: { "d-flex": "" } },
            [
              _c("ResizableText", {
                attrs: { message: _vm.currentMission.instructions }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.error
        ? _c(
            "v-flex",
            {
              staticClass: "error-message",
              attrs: { "d-flex": "", "align-center": "", "justify-center": "" }
            },
            [_vm._v(" " + _vm._s(_vm.error) + " ")]
          )
        : _vm._e(),
      _c(
        "v-flex",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.error,
              expression: "!error"
            }
          ],
          staticClass: "carousel-wrap",
          class: {
            show: _vm.gifs.length && _vm.mode !== "explain" && !_vm.error
          }
        },
        [
          _vm.currentPage === 0 || !_vm.isScribe
            ? _c("img", {
                staticClass: "prev-btn disabled",
                attrs: {
                  src: require("../../../../assets/carousel-arrow-gray-prev.png")
                }
              })
            : _c("img", {
                staticClass: "prev-btn",
                attrs: {
                  src: require("../../../../assets/carousel-arrow-orange-prev.png")
                },
                on: { click: _vm.prevSlide }
              }),
          _vm.loading || !_vm.isScribe
            ? _c("img", {
                staticClass: "next-btn disabled",
                attrs: {
                  src: require("../../../../assets/carousel-arrow-gray-next.png")
                }
              })
            : _c("img", {
                staticClass: "next-btn",
                attrs: {
                  src: require("../../../../assets/carousel-arrow-orange-next.png")
                },
                on: { click: _vm.nextSlide }
              }),
          _vm.gifs.length
            ? _c(
                "Carousel",
                {
                  key: "giphy-" + _vm.searchQuery + "-" + _vm.syncedQuery,
                  ref: "carousel",
                  staticClass: "carousel",
                  attrs: {
                    perPage: _vm.nOfImagesPerSearch,
                    paginationEnabled: false,
                    navigationEnabled: false,
                    mouseDrag: false
                  },
                  on: { pageChange: _vm.pageChange }
                },
                _vm._l(_vm.gifs, function(gif, i) {
                  return _c(
                    "Slide",
                    { key: "giphy-" + i },
                    [
                      _c(
                        "v-flex",
                        {
                          staticClass: "carousel-image-wrap",
                          staticStyle: { position: "relative" },
                          attrs: { "fill-height": "" }
                        },
                        [
                          gif.loaded === false || gif.loaded === "warning"
                            ? _c("v-progress-circular", {
                                staticClass: "spinner",
                                attrs: { indeterminate: "" }
                              })
                            : _vm._e(),
                          gif.loaded === "warning"
                            ? _c("div", { staticClass: "loading-warn" }, [
                                _vm._v(
                                  " Loading takes longer than usual. Try another one "
                                )
                              ])
                            : _vm._e(),
                          _c("img", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !!gif.loaded && gif.loaded !== "warning",
                                expression:
                                  "!!gif.loaded && gif.loaded !== 'warning'"
                              }
                            ],
                            staticClass: "carousel-image",
                            attrs: { src: gif.url },
                            on: {
                              click: function($event) {
                                _vm.foo = false
                              },
                              load: function($event) {
                                return _vm.onLoad(i)
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "text-fields-transition", mode: "out-in" } },
        [
          _vm.mode !== "explain"
            ? _c(
                "div",
                { staticClass: "mission-text-feild-wrap" },
                [
                  _vm.selectedGif
                    ? _c(
                        "v-layout",
                        {
                          attrs: {
                            row: "",
                            "fill-height": "",
                            "justify-center": ""
                          }
                        },
                        [
                          _c(
                            "v-flex",
                            {
                              staticClass: "back-to-search-wrap",
                              attrs: { shrink: "" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "back-to-search",
                                  class: { disabled: !_vm.isScribe },
                                  on: {
                                    click: function($event) {
                                      _vm.foo = true
                                    }
                                  }
                                },
                                [
                                  _vm._v(" << Back to"),
                                  _c("br"),
                                  _vm._v("search ")
                                ]
                              )
                            ]
                          ),
                          _c(
                            "v-flex",
                            [
                              _c("TextField", {
                                staticClass:
                                  "mission-text-feild caption-text-field",
                                class: {
                                  disabled: !(_vm.captionText || _vm.isScribe)
                                },
                                attrs: {
                                  placeholder: "Caption (required)",
                                  status: _vm.status,
                                  show: "show",
                                  buttonText: "SUBMIT",
                                  buttonStatus: _vm.buttonStatus
                                },
                                on: {
                                  onSubmit: _vm.submit,
                                  onChange: _vm.onCaptionChange
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            {
                              staticClass: "back-to-search-wrap",
                              staticStyle: { visibility: "hidden" },
                              attrs: { shrink: "" }
                            },
                            [
                              _c("div", { staticClass: "back-to-search" }, [
                                _vm._v("<< Back to"),
                                _c("br"),
                                _vm._v("search")
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    : _c("TextField", {
                        staticClass: "mission-text-feild search-text-field",
                        class: _vm.buttonStatus,
                        attrs: {
                          placeholder: _vm.placeholder,
                          status: _vm.status,
                          show: "show",
                          buttonText: "SEARCH"
                        },
                        on: { onSubmit: _vm.getGif }
                      })
                ],
                1
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }