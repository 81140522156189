<template>
  <v-layout column justify-center fill-height class="giphy-mission">
    <!-- Mission question -->
    <div class="powered-by">
      <a target="_blank" href="https://giphy.com/">
        <img
          style="width: 110px"
          :src="require('../../../../assets/giphy.png')"
        />
      </a>
    </div>
    <v-flex
      d-flex
      v-if="!gifs.length || mode === 'explain'"
      class="mission-instructions"
    >
      <ResizableText :message="currentMission.instructions" />
    </v-flex>
    <v-flex
      d-flex
      align-center
      justify-center
      class="error-message"
      v-if="error"
    >
      {{ error }}
    </v-flex>
    <v-flex
      v-show="!error"
      class="carousel-wrap"
      :class="{ show: gifs.length && mode !== 'explain' && !error }"
    >
      <img
        v-if="currentPage === 0 || !isScribe"
        class="prev-btn disabled"
        :src="require('../../../../assets/carousel-arrow-gray-prev.png')"
      />
      <img
        v-else
        class="prev-btn"
        @click="prevSlide"
        :src="require('../../../../assets/carousel-arrow-orange-prev.png')"
      />
      <img
        v-if="loading || !isScribe"
        class="next-btn disabled"
        :src="require('../../../../assets/carousel-arrow-gray-next.png')"
      />
      <img
        v-else
        class="next-btn"
        @click="nextSlide"
        :src="require('../../../../assets/carousel-arrow-orange-next.png')"
      />
      <Carousel
        :perPage="nOfImagesPerSearch"
        :paginationEnabled="false"
        :navigationEnabled="false"
        :mouseDrag="false"
        v-if="gifs.length"
        class="carousel"
        ref="carousel"
        :key="`giphy-${searchQuery}-${syncedQuery}`"
        @pageChange="pageChange"
      >
        <Slide v-for="(gif, i) in gifs" :key="`giphy-${i}`">
          <v-flex
            fill-height
            style="position: relative"
            class="carousel-image-wrap"
          >
            <v-progress-circular
              v-if="gif.loaded === false || gif.loaded === 'warning'"
              indeterminate
              class="spinner"
            />
            <div v-if="gif.loaded === 'warning'" class="loading-warn">
              Loading takes longer than usual. Try another one
            </div>
            <img
              @click="foo = false"
              :src="gif.url"
              class="carousel-image"
              @load="onLoad(i)"
              v-show="!!gif.loaded && gif.loaded !== 'warning'"
            />
          </v-flex>
        </Slide>
      </Carousel>
    </v-flex>
    <transition name="text-fields-transition" mode="out-in">
      <div class="mission-text-feild-wrap" v-if="mode !== 'explain'">
        <v-layout row fill-height justify-center v-if="selectedGif">
          <v-flex shrink class="back-to-search-wrap">
            <div
              class="back-to-search"
              :class="{ disabled: !isScribe }"
              @click="foo = true"
            >
              &lt;&lt; Back to<br />search
            </div>
          </v-flex>
          <v-flex>
            <TextField
              :placeholder="'Caption (required)'"
              :status="status"
              :show="'show'"
              :buttonText="'SUBMIT'"
              :buttonStatus="buttonStatus"
              @onSubmit="submit"
              @onChange="onCaptionChange"
              class="mission-text-feild caption-text-field"
              :class="{ disabled: !(captionText || isScribe) }"
            />
          </v-flex>
          <v-flex shrink class="back-to-search-wrap" style="visibility: hidden">
            <div class="back-to-search">&lt;&lt; Back to<br />search</div>
          </v-flex>
        </v-layout>
        <TextField
          v-else
          :placeholder="placeholder"
          :status="status"
          :show="'show'"
          :buttonText="'SEARCH'"
          @onSubmit="getGif"
          class="mission-text-feild search-text-field"
          :class="buttonStatus"
        />
      </div>
    </transition>
  </v-layout>
</template>

<script>
import { GameMixin } from "@/mixins"
import TextField from "./TextField"
import ResizableText from "./ResizableText.vue"
import { Carousel, Slide } from "vue-carousel"
import * as moment from "moment"
import { mapState } from "vuex"

const TIMEOUT_DELAY = 10000

export default {
  name: "Giphy",
  mixins: [GameMixin],
  components: {
    ResizableText,
    TextField,
    Carousel,
    Slide
  },
  props: {
    mode: String
  },
  async created() {
    this.$store.dispatch("subscribeToGiphy")
  },
  mounted() {
    // save the initial length
    // const waitingTextLength = this.waitingText.length
    // this.interval = setInterval(() => {
    //   if (this.waitingText.length > waitingTextLength + 2)
    //     this.waitingText = this.waitingText.substr(0, waitingTextLength)
    //   else this.waitingText = this.waitingText + "."
    // }, 600)

    if (this.canSync) {
      this.userIDToWatch = this.getRandomTeammateID()
    } else {
      // clean any gif records from the DB
      this.cleanSyncedGifs()
    }
  },
  // beforeDestroyed() {
  //   try {
  //     clearInterval(this.interval)
  //   } catch (e) {
  //     console.log(e.message)
  //   }
  // },
  data() {
    return {
      loading: false,
      error: null,
      // interval: null,
      waitingText: "Waiting on Scribe...",
      searchQuery: "",
      gifs: [],
      nOfImagesPerSearch: 1,
      currentPage: 0,
      foo: false,
      captionText: "",
      userIDToWatch: null
    }
  },
  watch: {
    currentMissionID() {
      if (this.canSync) {
        this.userIDToWatch = this.getRandomTeammateID()
      } else {
        // clean any gif records from the DB
        this.cleanSyncedGifs()
      }
    },
    currentGlobalTeam() {
      if (!this.isHost) return
      this.gifs = []
      this.userIDToWatch = this.getRandomTeammateID()
      const index = parseInt(this.syncedIndex)
      if (isNaN(index)) return
      this.$nextTick(() => {
        console.log("ANIMATE FOR HOST")
        if (this.$refs.carousel) this.$refs.carousel.goToPage(index)
      })
    },
    currentPage(value) {
      const index = parseInt(value)
      if (!this.canSync) {
        if (!isNaN(index)) {
          this.onIndexChange(index)
        }
      }
    },
    canSync(value) {
      if (value) {
        this.gifs = []
        this.userIDToWatch = this.getRandomTeammateID()
      } else {
        this.gifs = []
        this.userIDToWatch = null
      }
    },
    syncedGifs: {
      handler: function (value) {
        if (!value) return
        if (!Array.isArray(value)) return
        if (!value.length) {
          this.gifs = []
          return
        }
        // figure which are already in cache/loaded
        const loaded = this.gifs.filter(obj => {
          return obj.loaded && value.includes(obj.url)
        })
        // apply the update array of urls but with respect
        // to the loaded state
        this.gifs = value.map(url => {
          if (loaded.some(obj => obj.url === url)) {
            return {
              url,
              loaded: true
            }
          } else {
            return {
              url,
              loaded: false
            }
          }
        })
      },
      deep: true
    },
    syncedIndex(value) {
      const index = parseInt(value)
      if (isNaN(index)) return
      this.$nextTick(() => {
        if (this.$refs.carousel) this.$refs.carousel.goToPage(index)
      })
    }
  },
  computed: {
    ...mapState("group", ["currentGlobalTeam"]),
    syncedGifs() {
      if (!this.syncedGifObject) return []
      if (!Array.isArray(this.syncedGifObject.gifs)) return []
      return this.syncedGifObject.gifs
    },
    syncedQuery() {
      if (!this.syncedGifObject) return null
      return this.syncedGifObject.query
    },
    syncedIndex() {
      if (!this.syncedGifObject) return 0
      const index = parseInt(this.syncedGifObject.index)
      return isNaN(index) ? 0 : index
    },
    syncedGifObject() {
      if (!this.userIDToWatch) return null
      const found = this.otherTeamPlayersGiphies.find(({ userID }) => {
        return userID === this.userIDToWatch
      })
      if (!found) return null
      return {
        gifs: found.gifs,
        index: found.index,
        query: found.query
      }
    },
    canSync() {
      return (
        this.otherTeamPlayersGiphies.length > 0 &&
        !this.isScribe &&
        this.teamMission
      )
    },
    giphies() {
      return this.$store.getters.giphies
    },
    giphiesArray() {
      if (!this.giphies) return []
      const giphies = []
      for (const giphyID in this.giphies)
        giphies.push({ ...this.giphies[giphyID], id: giphyID })

      return giphies
    },
    otherTeamPlayersGiphies() {
      return this.teamGiphies.filter(userID => userID !== this.user.id)
    },
    isHost() {
      return this.user.role === "facilitator"
    },
    teamGiphies() {
      return this.giphiesArray.filter(({ teamID }) => {
        if (this.isHost) {
          return teamID === this.currentGlobalTeam
        } else {
          return teamID === this.user.teamID
        }
      })
    },
    selectedGif() {
      if (this.foo) return null
      if (!this.gifs.length) return null
      else return this.gifs[this.currentPage]
    },
    status() {
      if (this.isScribe) return "active"
      else return null
    },
    buttonStatus() {
      if (this.captionText == "") return "inactive"
      else return "active"
    },
    currentMission() {
      return this.$store.getters.getCurrentMission
    },
    placeholder() {
      // No data entered for a non scribe
      if (!this.isScribe) return this.waitingText
      // If scribe
      if (this.isScribe && this.mode !== "explain") return "Enter here..."
      // Scribe but not ready to go
      return "Wait..."
    },
    isScribe() {
      return this.$store.getters.isScribe
    },
    pageCount() {
      if (!this.nOfImagesPerSearch) return 1
      return Math.ceil(this.gifs.length / this.nOfImagesPerSearch)
    },
    currentMissionID() {
      return this.$store.getters.currentMission
    }
  },
  methods: {
    getRandomInt(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min
    },
    getRandomTeammateID() {
      const random = this.otherTeamPlayersGiphies[
        this.getRandomInt(0, this.otherTeamPlayersGiphies.length - 1)
      ]
      if (!random) throw new Error("No users on the team")
      return random.userID
    },
    cleanSyncedGifs() {
      const found = this.giphiesArray.find(({ userID, missionID, teamID }) => {
        return (
          userID === this.user.id &&
          teamID === this.user.teamID &&
          missionID === this.currentMissionID
        )
      })
      // console.log(found)
      if (found) {
        const timestamp = new Date()
        const obj = { ...found }
        obj.gifs = []
        obj.id = found.id
        obj.updated = moment(timestamp).format("LLLL")
        this.$store.dispatch("addGiphy", obj)
      }
    },
    onIndexChange(index) {
      const found = this.giphiesArray.find(({ userID, missionID, teamID }) => {
        return (
          userID === this.user.id &&
          teamID === this.user.teamID &&
          missionID === this.currentMissionID
        )
      })

      if (found) {
        const timestamp = new Date()
        const obj = { ...found }
        obj.index = index
        obj.id = found.id
        obj.updated = moment(timestamp).format("LLLL")
        this.$store.dispatch("addGiphy", obj)
      }
    },
    onGiphy(url, query) {
      const found = this.giphiesArray.find(({ userID, missionID, teamID }) => {
        return (
          userID === this.user.id &&
          teamID === this.user.teamID &&
          missionID === this.currentMissionID
        )
      })

      let obj
      const timestamp = new Date()

      if (found) {
        obj = { ...found }
        obj.gifs = []
        if (Array.isArray(found.gifs)) {
          if (found.query === query) {
            obj.gifs = found.gifs.map(gif => gif)
          }
        }
        obj.query = query
        obj.gifs.push(url)
        obj.id = found.id
        obj.updated = moment(timestamp).format("LLLL")
      } else {
        obj = {}
        obj.query = query
        obj.userID = this.user.id
        obj.teamID = this.user.teamID
        obj.missionID = this.currentMissionID
        obj.mission = this.currentMission.name
        obj.created = moment(timestamp).format("LLLL")
        obj.updated = null
        obj.gifs = [url]
      }

      this.$store.dispatch("addGiphy", obj)
    },
    onLoad(index) {
      if (this.gifs[index]) {
        clearTimeout(this.gifs[index].timeout)
        this.gifs[index].loaded = true
      }
    },
    onCaptionChange(str) {
      this.captionText = str
    },
    nextSlide() {
      if (this.currentPage === this.pageCount - 1) {
        this.getGif(null, () => {
          this.$nextTick(() => {
            this.$refs.carousel.goToPage(this.$refs.carousel.getNextPage())
          })
        })
      } else {
        this.$refs.carousel.goToPage(this.$refs.carousel.getNextPage())
      }
    },
    prevSlide() {
      this.$refs.carousel.goToPage(this.$refs.carousel.getPreviousPage())
    },
    getGifPromise(url) {
      return new Promise((resolve, reject) => {
        // timeout for getting a response from Giphy
        const timeout = setTimeout(() => {
          console.error("Giphy timeout error")
          reject(new Error("We can't find a good match. Try again"))
        }, TIMEOUT_DELAY)
        fetch(url)
          .then(response => response.json())
          .then(res => {
            // if Giphy internal error
            if (res.message) {
              return reject(new Error(res.message))
              // if not array
            } else if (!Array.isArray(res.data)) {
              console.error("Got a non array response from Giphy")
              return reject(new Error("We can't find a good match. Try again"))
              // if doesn't have any values
            } else if (!res.data.length) {
              return reject(
                new Error(
                  `Sorry, no matches found for "${this.searchQuery}". Try a different search query`
                )
              )
            }
            // clear timeout error timer
            clearTimeout(timeout)
            return resolve(res.data)
          })
      })
    },
    getGif(str, callback = () => {}) {
      const API_KEY = "4vO355CnfW0k9iIvWnQ4W0UKkcLX4X9R"
      const SEARCH_END_POINT = "https://api.giphy.com/v1/gifs/search?"
      const currentSearchQuery = this.searchQuery
      // update only with a non null value
      if (str) this.searchQuery = str
      this.loading = true

      if (!this.searchQuery) {
        this.error = "Please enter a search query"
        this.foo = false
        this.gifs = []
        this.currentPage = 0
        this.loading = false
        return callback()
      }

      const url = `${SEARCH_END_POINT}&api_key=${API_KEY}&q=${this.searchQuery}&limit=${this.nOfImagesPerSearch}&offset=${this.gifs.length}&rating=g`

      this.getGifPromise(url)
        .then(array => {
          console.log("got results from Giphy", array)
          // reset current error
          this.error = null
          // push data
          array.forEach(gif => {
            const url = `https://media.giphy.com/media/${gif.id}/giphy.gif`
            // if a new query clean the array
            if (str) {
              if (str !== currentSearchQuery) {
                this.foo = false
                this.gifs = []
                this.currentPage = 0
              }
            }
            this.loading = false

            const obj = {
              url,
              loaded: false
            }

            // timeout for loading the image
            const timeout = setTimeout(() => {
              obj.loaded = "warning"
            }, TIMEOUT_DELAY)

            obj.timeout = timeout

            this.gifs.push(obj)
            this.onGiphy(url, this.searchQuery)
            callback()
          })
        })
        .catch(error => {
          console.log(error.message)
          this.error = error.message
          this.foo = false
          this.loading = false
          this.gifs = []
          this.currentPage = 0
        })
    },
    pageChange(n) {
      this.currentPage = n
    },
    submit(string) {
      if (!this.isScribe) return console.log("No rights for submit")
      this.caption = string
      if (!this.selectedGif || !this.caption) return
      if (!this.selectedGif.url) return
      this.answer = this.selectedGif.url
      this.checkAnswer()
    }
  }
}
</script>

<style lang="scss">
.giphy-mission {
  padding-top: 20px;
  padding-bottom: 42px;
  position: relative;
  line-height: 1;
  font-size: 1em;
  position: relative;
  .loading-warn {
    position: absolute;
    width: 50%;
    top: 50%;
    left: 25%;
    margin-top: 25px;
    font-size: 12px;
  }
  .powered-by {
    position: absolute;
    top: 0;
    left: -2px;
    font-size: 9px;
  }
  .carousel-image-wrap {
    position: relative;
  }
  .spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    color: $primary_accent_color;
  }
  .error-message {
    color: $color-wrong;
  }
  .text-fields-transition-enter-active,
  .text-fields-transition-leave-active {
    transition: all ease 0.3s;
    opacity: 1;
    transform: translateY(0);
  }
  .text-fields-transition-enter,
  .text-fields-transition-leave-to {
    opacity: 0;
    transform: translateY(5px);
  }
  .mission-text-feild-wrap {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-left: 52px;
    padding-right: 52px;
    padding-bottom: 6px;
  }
  .back-to-search-wrap {
    align-items: center;
    justify-content: center;
    display: flex;
    padding-right: 5px;
  }
  .back-to-search {
    font-size: 0.8rem;
    line-height: 0.9rem;
    font-weight: bold;
    color: $primary_accent_color;
    &.disabled {
      color: $color-disabled;
      pointer-events: none;
    }
    &:not(.disabled) {
      cursor: pointer;
      &:hover {
        filter: brightness(1.2);
      }
    }
  }
  .mission-text-feild {
    min-width: 200px;
    & :nth-child(2n) {
      max-width: 0;
    }
  }
  .caption-text-field {
    min-width: 140px;
    max-width: 400px;
    & :nth-child(2n) {
      max-width: 0;
      &:hover {
        filter: brightness(1.2);
      }
      & > div {
        width: 80px;
        height: 100%;
        //background-color: $primary_accent_color;
        margin-left: 5px;
        color: $color-white;
        border-radius: 8px;
        a {
          color: $color-white;
        }
      }
    }
  }
  .v-text-field__slot {
    input {
      font-size: 15px !important;
    }
  }
  .caption-text-field.disabled {
    & :nth-child(2n) {
      &:hover {
        filter: none !important;
      }
      & > div {
        background-color: $color-disabled;
        filter: none !important;
        a {
          cursor: initial;
          filter: none !important;
        }
      }
    }
  }
  .search-text-field {
    margin: auto;
    width: 70%;
    min-width: 180px;
    max-width: 400px;
  }
  .mission-instructions {
    padding: 5px 10px 5px;
  }
  .VueCarousel-wrapper,
  .VueCarousel-inner,
  .VueCarousel {
    height: 100% !important;
  }
  .carousel-wrap {
    position: relative;
    opacity: 0;
    transition: all 0.3s ease-out;
    width: 100%;
    height: 100%;
    max-height: 0;
  }
  .carousel-wrap.show {
    opacity: 1;
    max-height: 100%;
  }
  .carousel-image {
    position: absolute;
    max-width: 100%;
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    padding: 8px;
    cursor: pointer;
  }
  .next-btn,
  .prev-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
      transform: translateY(-50%) scale(0.95);
    }
    &.disabled {
      pointer-events: none;
      cursor: not-allowed;
    }
  }
  .prev-btn {
    z-index: 2;
    left: 3%;
  }
  .next-btn {
    z-index: 2;
    right: 3%;
  }
}
</style>
